/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

export default function SkeletonParagraph({
  selectorId,
  columns,
  rowsPerColumn,
}) {
  return (
    <div id={selectorId}>
      <div className="skeleton-content-container">
        {Array.from({ length: columns }).map((column, indexColumn) => (
          <div className="skeleton-content-wrapper" key={`skeleton-content-row-${column}-${indexColumn}`}>
            {Array.from({ length: rowsPerColumn }).map((row, indexRow) => (
              <div className="animated-background" key={`skeleton-content-row-item-${row}-${indexRow}`}>&nbsp;</div>
            ))}
            <div className="animated-background half" key={`skeleton-content-row-half-item-${column}-${indexColumn}`}>&nbsp;</div>
          </div>
        ))}
      </div>

      <style jsx>
        {`
          .skeleton-content-wrapper {
            width: 100%;
          }

          .skeleton-content-container {
            display: flex;
          }

          .animated-background {
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeHolderShimmer;
            animation-timing-function: linear;
            background: #dddddd;
            background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
            background-size: 1000px 104px;
            border-radius: 10px;
            height: 10px;
            width: 90%;
            position: relative;
            margin-top: 20px;
          }

          .animated-background.half {
            width: 40%;
          }

          .animated-fade {
            animation-duration: 0.1s;
            animation-name: fadeIn;
            animation-timing-function: linear;
            animation-iteration-count: 1;
          }

          @keyframes placeHolderShimmer{
            0%{
                background-position: -468px 0
            }
            100%{
                background-position: 468px 0
            }
          }

          @keyframes fadeIn {
            0% {
              opacity: 0;
              transform: translateY(-30px);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }

          @media (max-width: 768px) {

            .skeleton-content-container {
                display: flex;
                flex-direction: column;
            }

          }
        `}
      </style>
    </div>
  );
}

SkeletonParagraph.defaultProps = {
  selectorId: 'skeleton-paragraph',
  columns: 1,
  rowsPerColumn: 2,
};

SkeletonParagraph.propTypes = {
  selectorId: PropTypes.string,
  columns: PropTypes.number,
  rowsPerColumn: PropTypes.number,
};
