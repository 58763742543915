import React from 'react';
import TextDiv from '@/components/text-div';
import ErrorIcon from '@/public/icons/error-large.svg';
import GreenTick from '@/public/icons/green-tick.svg';
import PropTypes from 'prop-types';
import SkeletonParagraph from '../skeleton-content/skeleton-paragraph';

export default function ErrorBox({ errorMessage, showTick }) {
  const errorDescription = () => {
    if (errorMessage === '...') {
      return (<SkeletonParagraph />);
    }
    return errorMessage || '404';
  };

  return (
    <div>
      <div className="content">
        <div className="error-icon">
          {showTick ? (
            <div id="success-tick">
              <GreenTick />
            </div>
          ) : <ErrorIcon />}
        </div>
        <div className="error-description">
          <TextDiv selectorId="error-box-text" className="global-title">
            {errorDescription()}
          </TextDiv>
        </div>
      </div>
      <style jsx>
        {`
          .content {
            text-align: center;
            padding: calc(var(--spacing) * 2)
          }

          .error-description {
            margin: 20px 0;
          }
        `}
      </style>
    </div>
  );
}

ErrorBox.defaultProps = {
  errorMessage: null,
  showTick: false,
};

ErrorBox.propTypes = {
  errorMessage: PropTypes.string,
  showTick: PropTypes.bool,
};
