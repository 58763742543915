import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AdminContext } from '@/contexts/admin-context';
import { ThemeContext } from '@/contexts/theme-context';

/**
 * A simple proceed button component for clicking
 *
 * @param {node} children any children to put inside the button
 * @param {function} onClick the button onclick handler
 * @param {function} onKeyDown the button onKeyDown handler
 * @param {string} inputSelectorId an ID to be passed to the button component
 * @param {number} inputHeight a value to configure the min-height
 * @param {boolean} largeButton whether we add larger button or not
 * @param {number} inputWidth a value to configure the min-width
 * @param {number} fontSize a value to configure the font-size
 * Font size may need to use react-responsive to ensure that the font size is right for the screen size
 * @param {boolean} hasMargin Whether or not the button should have a margin-top
 * @param fontWeight
 * @param {boolean} isLozenge true gives it the curved borders
 * @param {string} backgroundColor unknown
 * @param {string} textColor hmmm
 * @param {string} borderColor mystery
 * @param {boolean} disabled is the button disabled
 * @param ariaLabel
 * @param {boolean} useMerchantThemeColor
 * @param {string} as - Optional. The type of element to render. Defaults to 'button'.
 * @return {JSX.Element}
 */
export default function SimpleButton({
  children,
  onClick,
  onKeyDown,
  inputSelectorId,
  inputHeight,
  inputWidth,
  largeButton,
  hasMargin,
  fontSize,
  fontWeight,
  isLozenge,
  backgroundColor,
  noBorder,
  textColor,
  borderColor,
  disabled,
  ariaLabel,
  useMerchantThemeColor,
  centered,
  readOnly,
  as,

}) {
  const adminContext = useContext(AdminContext);
  const { primaryColor, hoverPrimaryColor, buttonTextColor } = useContext(ThemeContext);
  const defaultColor = backgroundColor.match(/--/) ? `var(${backgroundColor})` : backgroundColor;
  const isBorderColorConfigurable = adminContext?.getAdminConfigValue('theme')?.borderColorConfigurable;

  const getButtonColor = () => {
    if (useMerchantThemeColor) return primaryColor;
    return defaultColor;
  };

  const getButtonHoveredColor = () => {
    if (useMerchantThemeColor) return hoverPrimaryColor;
    return defaultColor;
  };

  const getButtonBorderColor = () => {
    if (noBorder) return 'none';
    if (useMerchantThemeColor && isBorderColorConfigurable) {
      return `1px solid ${primaryColor}`;
    }
    return `1px solid var(${borderColor})`;
  };

  const getButtonHoveredBorderColor = () => {
    if (noBorder) return 'none';
    if (useMerchantThemeColor && isBorderColorConfigurable) {
      return `1px solid ${hoverPrimaryColor}`;
    }
    return `1px solid var(${borderColor})`;
  };

  const getButtonTextColor = () => {
    if (buttonTextColor) return buttonTextColor;
    return `var(${textColor})`;
  };

  switch (as) {
    case 'div':
      return (
        <>
          <div
            id={inputSelectorId}
            aria-label={ariaLabel}
            className={`${centered ? 'centered' : ''}`}
          >
            {children}
          </div>
          <style jsx>
            {`
            div {
              background-color: ${getButtonColor()};
              border-radius: ${isLozenge ? '21px' : 'var(--border-radius)'};
              border: ${getButtonBorderColor()};
              color: ${getButtonTextColor()};
              cursor: pointer;
              font-family: inherit;
              font-size: var(${fontSize});
              font-weight: var(${fontWeight});
              height: auto;
              margin-top: ${hasMargin ? '20px' : 'initial'};
              min-height: ${inputHeight}px;
              min-width: ${inputWidth}px;
              padding: ${largeButton ? '10px 25px' : 'var(--spacing-extra-small)'};
              pointer-events: ${(disabled || readOnly) ? 'none' : 'initial'};
              opacity:${(disabled && !readOnly) ? '0.5' : '1'};
              width: auto;
              user-select: none;
            }
  
            div.centered {
              margin-left: auto;
              margin-right: auto;
            }
  
            div:hover {
              opacity: ${useMerchantThemeColor ? '1' : '0.7'};
              background-color: ${getButtonHoveredColor()};
              transition: ${useMerchantThemeColor ? 'background-color 0.1s linear' : 'opacity 0.1s linear'};
              border: ${getButtonHoveredBorderColor()};
            }
          `}
          </style>
        </>
      );
    case 'button':
    default:
      return (
        <>
          <button
            id={inputSelectorId}
            aria-label={ariaLabel}
            type="button"
            onClick={onClick}
            onKeyDown={onKeyDown}
            disabled={disabled}
            className={`${centered ? 'centered' : ''}`}
          >
            {children}
          </button>
          <style jsx>
            {`
            button {
              background-color: ${getButtonColor()};
              border-radius: ${isLozenge ? '21px' : 'var(--border-radius)'};
              border: ${getButtonBorderColor()};
              color: ${getButtonTextColor()};
              cursor: pointer;
              font-family: inherit;
              font-size: var(${fontSize});
              font-weight: var(${fontWeight});
              height: auto;
              margin-top: ${hasMargin ? '20px' : 'initial'};
              min-height: ${inputHeight}px;
              min-width: ${inputWidth}px;
              padding: ${largeButton ? '10px 25px' : 'var(--spacing-extra-small)'};
              pointer-events: ${(disabled || readOnly) ? 'none' : 'initial'};
              opacity:${(disabled && !readOnly) ? '0.5' : '1'};
              width: auto;
              user-select: none;
            }
  
            button.centered {
              margin-left: auto;
              margin-right: auto;
            }
  
            button:hover {
              opacity: ${useMerchantThemeColor ? '1' : '0.7'};
              background-color: ${getButtonHoveredColor()};
              transition: ${useMerchantThemeColor ? 'background-color 0.1s linear' : 'opacity 0.1s linear'};
              border: ${getButtonHoveredBorderColor()};
            }
          `}
          </style>
        </>
      );
  }
}

SimpleButton.defaultProps = {
  onKeyDown: () => { },
  fontSize: '--font-size-body',
  fontWeight: '--font-weight-body',
  inputSelectorId: null,
  inputHeight: 36,
  inputWidth: 123,
  largeButton: false,
  hasMargin: true,
  isLozenge: false,
  backgroundColor: '--color-white',
  textColor: '--color-primary-dark',
  borderColor: '--color-button-border',
  disabled: false,
  readOnly: false,
  centered: false,
  ariaLabel: undefined,
  useMerchantThemeColor: undefined,
  onClick: undefined,
  as: 'button',
  noBorder: false,
};

SimpleButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  inputSelectorId: PropTypes.string,
  inputHeight: PropTypes.number,
  largeButton: PropTypes.bool,
  inputWidth: PropTypes.number,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  hasMargin: PropTypes.bool,
  isLozenge: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  centered: PropTypes.bool,
  ariaLabel: PropTypes.string,
  useMerchantThemeColor: PropTypes.bool,
  as: PropTypes.string,
  noBorder: PropTypes.bool,
};
